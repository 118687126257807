:root {
    --primary-logo: #19212C;
    --secondary-logo: #A78150;

    --primary-blue: #010828; 
    --secondary-blue: #18294E;

    --primary-white: white;
    --primary-gold: #DAB075;
    --third-background: #F9F7F0;
    --cover-background-highlight: #dddddd;
    --submenu-primary-background: #f2effa;

    --shadow-highlight: #6d6d6d;

    --text: grey;
    --primary-blue-text: #010828; 
    --primary-white-text: white;
    --primary-gold-text: #DAB075;
    --secondary-text: #1B253F;
    --dark-text: #D0A66F;
}

html,
body {
    margin: 0;
    background-color: var(--background);
    color: var(--secondary-text);
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    text-transform: uppercase;

    & p{
        font-size: 16px;
        text-transform: none;
    }

    & label{
        font-size: 24px;
    }

    & h1 {
        font-size: 45px;
        font-family: "Roboto", sans-serif;
        margin: 0px;
        // font-weight: 800;
    }

    & h2 {
        font-family: "Roboto", sans-serif;
        font-size: 28px;
        margin: 0px;
        font-weight: bold;
    }

    & h3 {
        font-family: "Roboto", sans-serif;
        margin: 0px;
        font-size: 23px;
    }

    @media (max-width: 1200px) {
        body{
            font-size: 20px;
            & h1 {
                font-size: 35px;
            }
    
            & h2 {
                font-size: 20px;
            }
    
            & h3 {
                font-size: 18px;
            }

            & p{
                font-size: 14px;
            }
        }
    }
}